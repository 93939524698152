import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./navbar.css";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import DoneAllSharpIcon from "@mui/icons-material/DoneAllSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import SignalCellularAltSharpIcon from "@mui/icons-material/SignalCellularAltSharp";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import StorageIcon from '@mui/icons-material/Storage';

export default function Navbar() {
  let location = useLocation();
  let history = useHistory();

  const items = [
    {
      text: "Database Connection",
      icon: <StorageIcon />,
      route: "/database-connection",
    },
    {
      text: "Drilling Overview",
      icon: <FilterNoneIcon />,
      route: "/overview",
    },
    {
      text: "Drilling Optimisation",
      icon: <DoneAllSharpIcon />,
      route: "/drilling-optimisation",
    },
    
    {
      text: "Analytics",
      icon: <SignalCellularAltSharpIcon />,
      route: "/analytics",
    },
    {
      text: "Settings",
      icon: <SettingsIcon />,
      route: "/settings",
      disabled: true, // Disable the Settings button
    },
  ];

  const [activeRoute, setActiveRoute] = useState(location.pathname);

  useEffect(() => {
    // Set the active route based on the current location
    setActiveRoute(location.pathname);
  }, [location.pathname]);

  const handleClick = (route) => {
    if (!items.find((item) => item.route === route)?.disabled) {
      setActiveRoute(route);
      history.push(route);
    }
  };

  return (
    <div className="side-nav">
      <div className="item-container">
        <div className="side-nav-header">Drilling Optimization</div>
        <div className="side-nav-header-sub">AI engine</div>
        <Toolbar
          sx={{
            minHeight: { xs: "56px", sm: "40px" }, // Increase the min-height
          }}
        />
        <Divider />
        <List>
          {items.map((item) => (
            <div key={item.text}>
              <ListItem
                disablePadding
                className={
                  activeRoute === item.route ? "active-item" : "list-item"
                }
              >
                <ListItemButton
                  onClick={() => handleClick(item.route)}
                  disabled={item.disabled} // Disable the button if the item is disabled
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 39, // Adjust the minimum width here
                      color: activeRoute === item.route ? "#176C44" : "#FFFFFF",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      color: activeRoute === item.route ? "#176C44" : "#FFFFFF",
                    }}
                    primary={item.text}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </div>
    </div>
  );
}
