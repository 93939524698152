import { React, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { showToaster } from "../Services/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./AWSDBForm.module.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import ConnectingImg from "../../assets/images/connecting1.gif";
import { useHistory } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#176C44",
    },
    secondary: {
      main: "#09A359",
    },
  },
});

export default function AWSDbForm() {
  const [pageLoad, setPageLoad] = useState(false);
  const [wellId, setWellId] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState("aws");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const history = useHistory();

  const [databaseList, setDatabaseList] = useState([
    {
      name: "MySQL",
      value: "aws",
    },
    { name: "PostgreSQL", value: 2 },
    { name: "SQL Server", value: 3 },
  ]);
  const [storageList, setStorageList] = useState([
    {
      name: "AWS S3 ",
      value: "aws",
    },
    { name: "Google Cloud Storage ", value: "gcs" },
    { name: "Azure Blob Storage", value: "azure" },
    {
      name: "Local Folder",
      value: "local",
    },
  ]);
  const formik = useFormik({
    initialValues: {
      well_ID: "",
      current_Depth: "",
      port: "",
      temp_Out: "",
      database_name: "",
      username: "",
      password: "",
      sslmode: "",
      connection_timeout: "",
    },
    validationSchema: Yup.object().shape({
      well_ID: Yup.string().required("well ID is required"),
      current_Depth: Yup.string().required("Current Depth is required"),
      port: Yup.string().required("Port is required"),
      temp_Out: Yup.string().required("Temp Out is required"),
      database_name: Yup.string().required("Database Name is required"),
      username: Yup.string().required("Username is required"),
      password: Yup.string().required("Password is required"),
      sslmode: Yup.string().required("SSL Mode is required"),
      connection_timeout: Yup.string().required("h2s Pits is required"),
    }),

    onSubmit: (values) => {
      console.log("values", values);
      setPageLoad(true);
    },
  });
  const handleStorageChange = (event) => {
    setSelectedStorage(event.target.value);
  };
  const handleTestConnection = () => {
    // Simulate the connection test (3 seconds)
    setTimeout(() => {
      showToaster("Connection Successful!", "success");
      setIsConnected(true);
    }, 3000); // 3 seconds
  };
  const handleConnect = () => {
    setLoading(true); // Show GIF
    setOpenDialog(true); // Show dialog for testing connection
    setTimeout(() => {
      history.push("/overview");
    }, 3000); // 3 seconds
  
  };
  return (
    <>
      <div className={styles.outer}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="demo-multiple-name-label">Select Type</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            // multiple
            value={selectedStorage}
            onChange={handleStorageChange}
            input={<OutlinedInput label="Select Type" />}
            // MenuProps={MenuProps}
          >
            {storageList.map((well) => (
              <MenuItem
                key={well.name}
                value={well.value}
                // style={getStyles(name, wellId, theme)}
              >
                {well.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.outer_countiner}>
        {selectedStorage === "aws" && (
          <div>
            <h3>AWS S3 Configuration</h3>
            <TextField label="Bucket Name" fullWidth margin="normal" />
            <TextField label="Region" fullWidth margin="normal" />
            <TextField label="Access Key ID" fullWidth margin="normal" />
            <TextField
              label="Secret Access Key"
              type="password"
              fullWidth
              margin="normal"
            />
            <div className={styles.buttonContainer}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  // startIcon={<WhatshotIcon />}
                  sx={{
                    width: "130px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  disabled={!isConnected}
                    onClick={handleConnect}
                >
                  Connect
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}
        {selectedStorage === "gcs" && (
          <div>
            <h3>Google Cloud Storage Configuration</h3>
            <TextField label="Bucket Name" fullWidth margin="normal" />
            <TextField
              label="Service Account Key (JSON)"
              type="password"
              fullWidth
              margin="normal"
            />
            <div className={styles.buttonContainer}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  // startIcon={<WhatshotIcon />}
                  sx={{
                    width: "130px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  disabled={!isConnected}
                    onClick={handleConnect}
                >
                  Connect
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}
        {selectedStorage === "azure" && (
          <div>
            <h3>Azure Blob Storage Configuration</h3>
            <TextField label="Storage Account Name" fullWidth margin="normal" />
            <TextField label="Container Name" fullWidth margin="normal" />
            <TextField
              label="Account Key"
              type="password"
              fullWidth
              margin="normal"
            />
            <div className={styles.buttonContainer}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  // startIcon={<WhatshotIcon />}
                  sx={{
                    width: "130px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  disabled={!isConnected}
                  onClick={handleConnect}
                >
                  Connect
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}

        {selectedStorage === "local" && (
          <div>
            <h3>Local Folder Configuration</h3>
            <TextField label="Folder Path" fullWidth margin="normal" />
            <div className={styles.buttonContainer}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  // startIcon={<WhatshotIcon />}
                  sx={{
                    width: "130px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  disabled={!isConnected}
                    onClick={handleConnect}
                >
                  Connect
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}
      </div>
      {loading &&
        ((
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            {/* <DialogTitle>Connecting to Database</DialogTitle> */}
            <DialogContent>
              <div className="loading-animation-container">
                {/* You can add your Lottie animation here if needed */}
                <div className="loading-spinner"></div>
                <img src={ConnectingImg} width="210px" height="210px"></img>
                {/* <div className="loading-text">Connecting to Database...</div> */}
              </div>
            </DialogContent>
          </Dialog>
        ): "")}

      {/*
       <div className={styles.outer_countiner}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.input_container_dashboard}>
            <div>
              <FormControl sx={{ m: 1, width: 400 }}>
                <InputLabel id="demo-multiple-name-label">
                  Select Database
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  // multiple
                  //   value={wellId}
                  //   onChange={(event) => handleWellIdChange(event)}
                  input={<OutlinedInput label=" Select Database" />}
                  // MenuProps={MenuProps}
                >
                  {databaseList.map((well) => (
                    <MenuItem
                      key={well.name}
                      value={well.value}
                      // style={getStyles(name, wellId, theme)}
                    >
                      {well.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.current_Depth}
                type="text"
                id="Hostname"
                name="Hostname"
                fullWidth
                label="Hostname "
                variant="outlined"
                size="small"
              />
              {formik.touched.Hostname && formik.errors.Hostname ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.Hostname}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.port}
                type="text"
                id="port"
                name="port"
                fullWidth
                label="Port"
                variant="outlined"
                size="small"
              />
              {formik.touched.port && formik.errors.port ? (
                <div className={styles.inputerrormsg}>{formik.errors.port}</div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.database_name}
                type="text"
                id="database_name"
                name="database_name"
                fullWidth
                label="Database Name "
                variant="outlined"
                size="small"
              />
              {formik.touched.database_name && formik.errors.database_name ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.database_name}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.username }
                type="text"
                id="username "
                name="username "
                fullWidth
                label="Username"
                variant="outlined"
                size="small"
              />
              {formik.touched.username && formik.errors.username ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.username}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.password}
                type="text"
                id="password"
                name="password"
                fullWidth
                label="Password"
                variant="outlined"
                size="small"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.sslmode }
                type="text"
                id="sslmode "
                name="sslmode "
                fullWidth
                label="SSL Mode"
                variant="outlined"
                size="small"
              />
              {formik.touched.sslmode && formik.errors.sslmode ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.sslmode}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.connection_timeout}
                type="text"
                id="connection_timeout "
                name="connection_timeout "
                fullWidth
                label="Connection Timeout "
                variant="outlined"
                size="small"
              />
              {formik.touched.connection_timeout &&
              formik.errors.connection_timeout ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.connection_timeout}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.temp_Out}
                type="text"
                id="temp_Out"
                name="temp_Out"
                fullWidth
                label="Temp Out (°C) "
                variant="outlined"
                size="small"
              />
              {formik.touched.temp_Out && formik.errors.temp_Out ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.temp_Out}
                </div>
              ) : null}
            </div>

            <div className={styles.button_container}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                >
                  Test Connection
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </form>
      </div> */}
    </>
  );
}
