import { React, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Button, createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { showToaster } from "../Services/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "./RelationalDb.module.css";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import ConnectingImg from "../../assets/images/connecting1.gif";
import { useHistory } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#176C44",
    },
    secondary: {
      main: "#09A359",
    },
  },
});

export default function NonrelationalDb() {
  const [pageLoad, setPageLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [wellId, setWellId] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const history = useHistory();

  const [databaseList, setDatabaseList] = useState([
    {
      name: "MongoDB",
      value: 1,
    },
    { name: "Cassandra", value: 2 },
    { name: "DynamoDB", value: 3 },
  ]);
  const formik = useFormik({
    initialValues: {
      well_ID: "",
      current_Depth: "",
      port: "",
      temp_Out: "",
      database_name: "",
      username: "",
      password: "",
      sslsettings: "",
      connection_timeout: "",
    },
    validationSchema: Yup.object().shape({
      well_ID: Yup.string().required("well ID is required"),
      current_Depth: Yup.string().required("Current Depth is required"),
      port: Yup.string().required("Port is required"),
      temp_Out: Yup.string().required("Temp Out is required"),
      database_name: Yup.string().required("Database Name is required"),
      username: Yup.string().required("Access key / Username is required"),
      password: Yup.string().required("Secret Key / Password is required"),
      sslsettings: Yup.string().required("SSL / SSL Settings is required"),
      connection_timeout: Yup.string().required("h2s Pits is required"),
    }),

    onSubmit: (values) => {
      console.log("values", values);
      setPageLoad(true);
    },
  });
  const handleTestConnection = () => {
    // Simulate the connection test (3 seconds)
    setTimeout(() => {
      showToaster("Connection Successful!", "success");
      setIsConnected(true);
    }, 3000); // 3 seconds
  };
  const handleConnect = () => {
    setLoading(true); // Show GIF
    setOpenDialog(true); // Show dialog for testing connection
    setTimeout(() => {
      history.push("/overview");
    }, 3000); // 3 seconds
  
  };
  return (
    <>
      <div className={styles.outer_countiner}>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.input_container_dashboard}>
            <div>
            <FormControl sx={{  width: "100%" }}>
                <InputLabel id="demo-multiple-name-label">
                  Select Database
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  // multiple
                  //   value={wellId}
                  //   onChange={(event) => handleWellIdChange(event)}
                  input={<OutlinedInput label=" Select Database" />}
                  // MenuProps={MenuProps}
                >
                  {databaseList.map((well) => (
                    <MenuItem
                      key={well.name}
                      value={well.value}
                      // style={getStyles(name, wellId, theme)}
                    >
                      {well.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.current_Depth}
                type="text"
                id="cluster_url"
                name="cluster_url"
                fullWidth
                label="Cluster URL "
                variant="outlined"
                size="small"
              />
              {formik.touched.cluster_url && formik.errors.cluster_url ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.cluster_url}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.port}
                type="number"
                id="port"
                name="port"
                fullWidth
                label="Port"
                variant="outlined"
                size="small"
              />
              {formik.touched.port && formik.errors.port ? (
                <div className={styles.inputerrormsg}>{formik.errors.port}</div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.database_name}
                type="text"
                id="database_name"
                name="database_name"
                fullWidth
                label="Database Name "
                variant="outlined"
                size="small"
              />
              {formik.touched.database_name && formik.errors.database_name ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.database_name}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.username }
                type="text"
                id="username"
                name="username"
                fullWidth
                label="Access key/Username"
                variant="outlined"
                size="small"
              />
              {formik.touched.username && formik.errors.username ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.username}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.password}
                type="password"
                id="password"
                name="password"
                fullWidth
                label="Secret key/Password"
                variant="outlined"
                size="small"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
            <div>
              <TextField
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // value={formik.values.sslsettings }
                type="text"
                id="sslsettings"
                name="sslsettings"
                fullWidth
                label="SSL / TLS Settings "
                variant="outlined"
                size="small"
              />
              {formik.touched.sslsettings && formik.errors.sslsettings ? (
                <div className={styles.inputerrormsg}>
                  {formik.errors.sslsettings}
                </div>
              ) : null}
            </div>
            <div></div>
            <div></div>

            <div className={styles.button_container}>
              <ThemeProvider theme={theme}>
                <Button
                  disabled={pageLoad}
                  type="submit"
                  startIcon={<WhatshotIcon />}
                  sx={{
                    width: "230px",
                    borderRadius: "10px",
                    fontWeight: "600",
                    colour: "white",
                  }}
                  variant="contained"
                  onClick={handleTestConnection}
                >
                  Test Connection
                </Button>
              </ThemeProvider>
            </div>
            <div></div>
            <div>
              <div className={styles.button_container}>
                <ThemeProvider theme={theme}>
                  <Button
                    disabled={pageLoad}
                    type="submit"
                    // startIcon={<WhatshotIcon />}
                    sx={{
                      width: "130px",
                      borderRadius: "10px",
                      fontWeight: "600",
                      colour: "white",
                    }}
                    variant="contained"
                    disabled={!isConnected}
                    onClick={handleConnect}
                  >
                    Connect
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </form>
      </div>
      {loading &&
        ((
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            {/* <DialogTitle>Connecting to Database</DialogTitle> */}
            <DialogContent>
              <div className="loading-animation-container">
                {/* You can add your Lottie animation here if needed */}
                <div className="loading-spinner"></div>
                <img src={ConnectingImg} width="210px" height="210px"></img>
                {/* <div className="loading-text">Connecting to Database...</div> */}
              </div>
            </DialogContent>
          </Dialog>
        ): "")}
    </>
  );
}
