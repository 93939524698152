import { React, useState } from "react";
import PageLoader from "../Services/pageLoader";
import styles from "./DatabaseConnection.module.css";
import NavBar from "../Dashboard/Navbar/navbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Header from "../Dashboard/Headers/header";
import RelationalDbForm from "./RelationalDbForm";
import NonrelationalDb from "./NonrelationalDb";
import TabPanel from "@mui/lab/TabPanel"; // Ensure you have this or use your own TabPanel implementation
import AWSDbForm from "./AwsDBForm";

// Function for displaying content of the selected tab
function TabContent({ value, index, children }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default function DatabaseConnection() {
  const [pageLoad, setPageLoad] = useState(false);
  const [value, setValue] = useState(0); // Initially select first tab

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={styles.dashboard_container}>
        <div className={styles.nav_container}>
          <NavBar />
        </div>
        <div className={styles.dashboard_item_container}>
          <Header />
          <div className={styles.grid_container}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
            //   textColor="#868786b0"
              //   indicatorColor="primary"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#176C444D", // Custom indicator color
                },
              }}
            >
              <Tab
                label="Relational Database"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#868786b0",
                  "&.Mui-selected": {
                    color: "#176C44",
                    //   backgroundColor: "#176C44",
                    // borderRadius: "5px",
                  },
                }}
              />
              <Tab
                label="Non-Relational Database"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#868786b0",
                  "&.Mui-selected": {
                    color: "#176C44",
                    //   backgroundColor: "#176C44",
                    // borderRadius: "5px",
                  },
                  
                }}
              />
              <Tab
                label="Cloud or Local Folder"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#868786b0",
                  "&.Mui-selected": {
                    color: "#176C44",
                    //   backgroundColor: "#176C44",
                    // borderRadius: "5px",
                  },
                }}
              />
            </Tabs>

            {/* Tab Content */}
            <TabContent value={value} index={0}>
              <RelationalDbForm />
            </TabContent>
            <TabContent value={value} index={1}>
              <NonrelationalDb />
            </TabContent>
            <TabContent value={value} index={2}>
              <AWSDbForm />
            </TabContent>
          </div>
        </div>
      </div>
    </>
  );
}
